import React, {FC, useEffect, useState, memo} from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import {arrowDownIcon} from '../../images/svgIcons';
import './styles.scss';

const DataList: FC<any> = ({openTop, list, value, placeholder, onChange, fullData, label, width, className}) => {
  const [active, selectRef, setActive] = useClickOutside();
  const [clicked, setClicked] = useState(false);
  const [filterdList, setfilterdList] = useState(list);

  useEffect(() => {
    setfilterdList(list);
  }, [list]);

  useEffect(() => {
    if (value && clicked) {
      const newList =
        list &&
        list.filter((item: any) => {
          if (item && typeof item === 'string') {
            return item.toLowerCase().includes(value.toString().toLowerCase());
          }
          if (item && typeof item === 'object') {
            if (typeof item.label === 'string') {
              return item.label.toLowerCase().includes(value.toString().toLowerCase());
            } else {
              return +item.label
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase());
            }
          }
          return;
        });
      setfilterdList(newList);
      setActive(true);
      setClicked(true);
    } else {
      setfilterdList(list);
    }
  }, [value, clicked]);

  // useEffect(() => {
  //   if (filterdList.length === 0) {
  //     setErrorMessage && setErrorMessage('Invalid value provided');
  //   } else {
  //     setErrorMessage && setErrorMessage('');
  //   }
  // }, [filterdList]);

  useEffect(() => {
    setActive(false);
    setClicked(false);
  }, []);

  return (
    <div className="d-block">
      {label && <div className="select-label primary-color">{label}</div>}
      <div
        id="datalist"
        className={`${active && clicked ? 'active' : ''}`}
        style={{width: width || '', minWidth: width || ''}}
        ref={selectRef}
      >
        <div className="form-item ">
          <input
            id="datalist-input"
            className={`input-field ${filterdList?.length === 0 ? 'error-border' : ''} ${className ? className : ''} `}
            type="text"
            value={value}
            placeholder={placeholder || 'type text'}
            onChange={e => {
              onChange(e.target.value);
              setClicked(true);
            }}
            onFocus={() => {
              setActive(true);
              setClicked(true);
            }}
            style={{minWidth: '100px'}}
            autoComplete="off"
          />
          <div className="arrow-img" onClick={() => setActive(!active)}>
            {arrowDownIcon}
          </div>
        </div>

        {active && clicked && filterdList && filterdList.length > 0 && (
          <>
            <ul id="datalist-ul" className={`datalist-ul ${openTop ? 'open-top' : ''}`}>
              {filterdList &&
                filterdList.map((item: any) => {
                  if (item && item.length && typeof item === 'string') {
                    return (
                      <li
                        key={item}
                        id={item}
                        onClick={() => {
                          setClicked(false);
                          onChange(item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  }
                  if (item && typeof item === 'object') {
                    return (
                      <li
                        key={item.value}
                        id={item.label}
                        onClick={() => {
                          setClicked(false);
                          onChange(item.label);
                        }}
                      >
                        {item.label}
                      </li>
                    );
                  }
                  return;
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(DataList);
