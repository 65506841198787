import React from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import GrammarSpellCheckerWrapper from '../../../components/tools-components/grammar-spell-checker';

const GrammarSpellChecker = () => (
  <Layout>
    <PageHero
      title="Check any web page for spelling and grammar mistakes"
      titleSmall
      subtitle="Catch embarrassing typos, spelling, and grammar mistakes on any web page in seconds."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <GrammarSpellCheckerWrapper />
    </Section>
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 mt-4 text-center">Grammar & Spelling FAQ</h4>
        <FAQItem
          title="How to check an entire website for grammar and spelling mistakes?"
          text={
            <p>
              Our Hexomatic website grammar and spelling checker enables you to catch grammar, spelling, or style
              mistakes in seconds. After scanning the website, our tool also suggests corrections to help you make your
              content better. All you need to do is insert your websites, select the language of your website and click
              Start to proceed with the instant check.
            </p>
          }
        />
        <FAQItem
          title="Who needs a website grammar and spelling checker?"
          text={
            <>
              <p>
                Every website owner needs to use a grammar and spelling checker as poor website grammar, spelling and
                style can affect the impression of the website and the business, the search rankings, and organic
                traffic.
              </p>
              <p>
                Each visitor to your website expects high-quality content to have a great visitor experience. So do
                Google. For Google, the visitor experience is a priority for tracking your website ranking. A website
                full of grammar and spelling mistakes will make your page demoted in favor of your competitors.
              </p>
            </>
          }
        />
        <FAQItem
          title="What are the advantages of an online website grammar checker?"
          text={
            <>
              <p>Online website grammar and spelling checker tools can be useful in many ways. </p>
              <p>
                First and foremost, your website grammar affects perceptions of your brand and the level of
                professionalism. Bad grammar and spelling lead to a bad brand reputation. Using a website grammar
                checker will help you find mistakes on time and correct them immediately making your website 100%
                error-free.
              </p>
              <p>
                Second, using website grammar checkers is time-saving. Our Hexomatic online grammar checker allows you
                to check the grammar of any web page in seconds. Just add your website URL and specify the language.
              </p>
              <p>
                Last but not least, website grammar checkers are ideal for the clarity of communication. The better your
                website grammar is, the easier it is to understand your brand message.
              </p>
            </>
          }
        />
        <FAQItem
          title="What is the difference between grammar and spelling?
          "
          text={
            <>
              <p>
                Grammar and spelling are integral parts of any language. Without either, there will be a collection of
                letters without any meaning. However, many people wonder how these two differ.{' '}
              </p>
              <p>
                Grammar is the correct arrangement of words for forming sentences while Spelling is the proper
                arrangement of letters for forming a word. Sentences will not make sense without the correct arrangement
                of words.
              </p>
            </>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default GrammarSpellChecker;
