import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/grammar/one.png';
import picture1 from '../../../images/tools/grammar/two.png';
import {spellcheckLanguages} from '../../../helpers/constants';

const Lang = ({country, lang}: any) => (
  <div className="language d-flex justify-content-center align-items-center m-2 p-2">
    <div className="select-lang">
      <div className="text-center">{country}</div>
      <div className="text-center">{lang}</div>
    </div>
  </div>
);

const Info = () => (
  <div className="grammar-info row mt-0 mx-0 mb-3">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Proofread any text or an entire page</h4>
      <div className="mb-2">
        First impressions matter, a lot. When website visitors encounter style, spelling, or grammar mistakes it
        immediately impacts their impression of your business.
      </div>
      <div className="mb-4">
        To make matters worse, these errors can linger for months unseen…Wasting your ad budget and costing your
        business lost sales.
      </div>
      <div className="mb-4">
        Search engines understand this too, and now penalize low-quality content which could affect your search rankings
        and organic traffic.
      </div>
      <div className="mb-4">
        Use our free tool to scan any web page for spelling, grammar, and style errors in seconds.
      </div>
      <div>
        <Button className="px-0" onClick={() => navigate('/registration')}>
          Sign up for Hexomatic account to increase your limits
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture} alt="Grammar & spelling page audit" />
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-3">
      <img src={picture1} alt="Grammar & spelling page audit" />
    </div>
    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">Scan entire websites for spelling or grammar mistakes with Hexomatic</h4>
      <div className="mb-2">
        Upgrade to a paid Hexomatic plan to access our sitewide grammar and spelling audit for the ultimate peace of
        mind.
      </div>
      <div className="mb-4">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready-made automations to
        perform spelling, grammar or accessibility audits in minutes and delegate time-consuming sales, marketing or
        research tasks.
      </div>
      <div>
        <Button onClick={() => navigate('/tools/')}>Click here to find out more</Button>
      </div>
    </div>

    <div className="col-12 pl-4">
      <h5 className="">Catch spelling or grammar mistakes in 32 languages</h5>
      <div className="">Ensure error free content on your web pages for 32 different languages including:</div>
    </div>
    <div className="col-12 mb-2 d-flex countries change-order-5">
      {spellcheckLanguages.map(({country, lang}) => (
        <Lang country={country} lang={lang} />
      ))}
    </div>
  </div>
);

export default Info;
